/*
👋 “Hey” from the Praxis team!
'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.
For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env
*/

import merge from 'lodash/merge'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  analyticsUrl: {
    external:
      'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    internal:
      'https://stgapi-internal.target.com/consumers/v1/ingest/internal/internal_app',
  },
  apiKey: '',
  appName: 'Skylight',
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    clientId: '',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
  authPol: {
    authorizationUrl:
      'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
    clientId: '',
    logoutUrl:
      'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
  },
  bannerEnvDisplay: '',
  entitlements: {} as any,
  env: 'dev',
  externalLinks: {} as { [key: string]: string },
  microFrontEnds: {} as { [key: string]: string },
  services: {} as { [key: string]: string },
  title: 'Properties-SkylightDEV-Application',
  toss: {} as { [key: string]: string },
  webhooks: {} as { [key: string]: string },
}

const envConfigs: { [environment: string]: object } = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  dev: {
    apiKey: '00ca0235d0b52d9604e6f92dee9d92b167703233', // skylight stage api key
    auth: { clientId: 'skylight_npe_im', redirectUri: window.location.origin },
    authPol: {
      clientId: 'skylight_npe_im',
      redirectUri: window.location.origin,
    },
    bannerEnvDisplay: 'DEV',
    entitlements: {
      catalog: {
        read: 'APP-OAUTH2-SKY-CatalogMgtRead-NP',
        update: 'APP-OAUTH2-SKY-CatMgtUpdate-NP',
      },
      catItem: {
        create: 'APP-OAUTH2-SKY-CatItemCreate-NP',
        read: 'APP-OAUTH2-SKY-CatItemRead-NP',
        update: 'APP-OAUTH2-SKY-CatItemUpdate-NP',
      },
      concept: { update: 'APP-OAUTH2-SKY-ConceptUpdate-NP' },
      demand: {
        create: 'APP-OAUTH2-SKY-Demand-Write-NP',
        read: 'APP-OAUTH2-SKY-Demand-Read-NP',
      },
      i3Demand: { read: 'APP-OAUTH2-SKY-DemandRead-NP' },
      i3Order: { read: 'APP-OAUTH2-SKY-OrderRead-NP' },
      impactedAreas: { update: 'APP-OAUTH2-SKY-ImpAreaUpdate-NP' },
      intake: {
        create: 'APP-OAUTH2-SKY-IntkMgmtCreate-NP',
        read: 'APP-OAUTH2-SKY-IntkMgmtRead-NP',
        update: 'APP-OAUTH2-SKY-IntkMgmtUpdate-NP',
      },
      item: {
        create: 'APP-OAUTH2-SKY-ItemMgtCreate-NP',
        read: 'APP-OAUTH2-SKY-ItemMgtRead-NP',
        update: 'APP-OAUTH2-SKY-ItemMgtUpdate-NP',
        request: 'APP-OAUTH2-MOSAIC-REQUEST-NPE',
      },
      milestoneManagement: {
        admin: 'APP-OAUTH2-PPM-Admin-P',
        create: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
        read: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
        update: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
      },
      nri: {
        update: 'APP-OAUTH2-FIXT-FixtAdmin-NPE',
      },
      pol: { read: 'APP-CPMS' },
      procurement: {
        create: 'APP-OAUTH2-SKY-ProcureCreate-NP',
      },
      project: {
        create: 'APP-OAUTH2-SKY-ProjMgtCreate-NP',
        read: 'APP-OAUTH2-SKY-ProjMgtRead-NP',
        update: 'APP-OAUTH2-SKY-ProjMgtUpdate-NP',
      },
      projectLocations: {
        create: 'APP-OAUTH2-SKY-ProjLocCreate-NP',
        read: 'APP-OAUTH2-SKY-ProjLocRead-NP',
      },
      purchase: { create: 'APP-OAUTH2-SKY-ProcureCreate-NP' },
      schedule: { create: 'APP-OAUTH2-SKY-SchdMgmtCreate-NP' },
      sourcing: {
        create: 'APP-OAUTH2-SKY-SourcingCreate-NP',
        read: 'APP-OAUTH2-SKY-SourcingRead-NP',
        update: 'APP-OAUTH2-SKY-SourcingUpdate-NP',
      },
      team: { update: 'APP-OAUTH2-SKY-TeamMgmtUpdate-NP' },
      ttsAdmin: { create: 'APP-OAUTH2-SKY-TTSAdmin-NP' },
      bimAdmin: { read: 'APP-SPACE-INFRA-ADMIN' },
      bimManagementAdmins: { create: 'APP-OAUTH2-SKY-BimAdminCreate-NP' },
      spatio: {
        create: 'APP-OAUTH2-SKY-SptProjCreate-NP',
        read: 'APP-OAUTH2-SKY-SptProjRead-NP',
        update: 'APP-OAUTH2-SKY-SptProjUpdate-NP',
      },
      vendorAssignment: {
        create: 'APP-OAUTH2-SKY-VndAsnmt-Write-NP',
        read: 'APP-OAUTH2-SKY-VndAsnmt-Read-NP',
      },
      preBuild: {
        read: 'APP-OAUTH2-SKY-Prebuild-CPM-NP',
        create: 'APP-OAUTH2-SKY-Prebuild-CPM-NP',
      },
    },
    externalLinks: {
      horizon: 'https://horizon.dev.target.com',
      mosaic: 'https://mosaic.dev.target.com',
      mosaicApiBaseUrl: 'https://mosaic-api-dev.dev.target.com',
      mosaicApiUrl: 'https://mosaic-api-dev.dev.target.com',
      procore:
        'https://saml.iam.target.com/affwebservices/public/saml2sso?SPID=https://login.procore.com/',
      spatio_reports: 'https://greenfield.target.com/dashboard/19625/0?$',
    },
    microFrontEnds: {
      BimUserManagement:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-bim-user-management/latest',
      CatalogDetails: 'https://ipskylightmfes-catalogdetails.dev.target.com',
      CatalogImageBulkUpload:
        'https://ipskylightmfes-catalogimagebulkupload.dev.target.com/',
      CatalogItemDetails:
        'https://ipskylightmfes-catalogitemdetails.dev.target.com',
      CatalogItemDetailsAriba:
        'https://ipskylightmfes-catalogitemdetails-ariba.dev.target.com',
      CatalogItemDetailsCatalogs:
        'https://ipskylightmfes-catalogitemdetails-catalogs.dev.target.com',
      CatalogItemDetailsNri:
        'https://ipskylightmfes-catalogitemdetails-nri.dev.target.com',
      CatalogItemMultiEdit:
        'https://ipskylightmfes-catalogitemmultiedit.dev.target.com',
      CatalogItemNriCreate:
        'https://ipskylightmfes-catalogitemnricreate.dev.target.com',
      CatalogItemSearch:
        'https://ipskylightmfes-catalogitemsearch.dev.target.com',
      CatalogUpdatesList:
        'https://ipskylightmfes-catalogupdateslist.dev.target.com',
      CpmFileUpload:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-cpm-file-upload/latest',
      DesignInitiatives:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-design-initiatives/latest',
      DirectReportMfeUrl:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-direct-report/latest',
      ExpiringContractsMfe:
        'https://ipskylightmfes-expiringcontracts.dev.target.com',
      HomePage:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-home-page/latest',
      HomePageCpm:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-home-page-cpm/latest',
      HznItemDetails: 'https://ipskylightmfes-itemdetails.dev.target.com',
      HznItemCatalogs: 'https://ipskylightmfes-itemcatalogs.dev.target.com',
      HznSourcingGroupDetails:
        'https://ipskylightmfes-sourcinggroupdetails.dev.target.com',
      HznSourcingGroupItems:
        'https://ipskylightmfes-sourcinggroupitems.dev.target.com',
      HznSourcingGroups: 'https://ipskylightmfes-sourcinggroups.dev.target.com',
      HznSourcingGroupsMultiEdit:
        'https://ipskylightmfes-sourcinggroupsmultiedit.dev.target.com',
      HznSourcingGroupNotes:
        'https://ipskylightmfes-sourcingdetailsnotes.dev.target.com',
      HznSupplierCatalogs:
        'https://ipskylightmfes-supplier-catalogs.dev.target.com',
      I3Demand: 'https://mfei3demand.dev.target.com/',
      I3DemandDetails: 'https://mfei3demanddetails.dev.target.com/',
      I3DemandOrderDetails: 'https://mfei3demandorderdetails.dev.target.com/',
      I3DemandSignals: 'https://mfei3demandsignals.dev.target.com/',
      I3Outbound: 'https://mfei3outbound.dev.target.com/',
      I3OutboundOrder: 'https://mfei3outboundorder.dev.target.com/',
      I3OutboundPart: 'https://mfei3outboundpart.dev.target.com/',
      I3OutboundStore: 'https://mfei3outboundstore.dev.target.com/',
      ImpactedAreas:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-impacted-areas/latest',
      ImplementationHistory:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-implementation-history/latest',
      IndirectItemDesignProcess:
        'https://mfeindirectitemdesignprocessnri.dev.target.com/mfe.js',
      IndirectItemDetailsNri:
        'https://mfeindirectitemdetailsnri.dev.target.com/',
      IndirectItemDocuments:
        'https://mfeindirectitemdocumentsnri.dev.target.com/',
      IndirectItemMultiEdit:
        'https://ipskylightmfes-indirectitemsmultiedit.dev.target.com',
      IndirectItemRelatedItems:
        'https://mfeindirectitemrelateditemsnri.dev.target.com/mfe.js',
      IndirectItemSearch:
        'https://mfeindirectitemsearchnri.dev.target.com/mfe.js',
      IndirectItemRequests:
        'https://nriskylightmfes-indirectitemrequests.dev.target.com/mfe.js',
      IndirectItemMultiDocDownload:
        'https://mfeindirectitemmultidocdownload.dev.target.com/mfe.js',
      IndirectItemReports:
        'https://nriskylightmfes-indirectitemreports.dev.target.com/',
      IndirectItemVariations:
        'https://nriskylightmfes-indirectitemvariations.dev.target.com/mfe.js',
      ItemAttrCompletionReports:
        'https://ipskylightmfes-itemattrcompletion.dev.target.com',
      LocationsSearch:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-locations-search/latest',
      LocLevelTeamAssignments:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-loc-team-assignments/latest',
      MilestoneManagement:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-milestone-management/latest',
      MissingAttributionIndirectPlanner:
        'https://ipskylightmfes-missingattrindirectplanner.dev.target.com',
      PotentialImpact:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-potential-impact/latest',
      ProgramDetails:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-details/latest',
      ProgramList:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-list/latest',
      ProgramLocations:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-locations/latest',
      ProgramProjects:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-projects/latest',
      ProgramViewLocations:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-view-locations/latest',
      ProgramProjectChanges:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-program-project-changes/latest',
      ProjectDetails:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-project-details/latest',
      ProjectSchedules:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-project-schedules/latest',
      ProjectTeamUpdate:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-project-team-update/latest',
      Purchase: 'https://ipskylightmfes-volumebasedupload.dev.target.com',
      PurchaseUploadsSearch:
        'https://ipskylightmfes-purchaseuploadssearch.dev.target.com',
      ReassignWorkMfeUrl:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-reassign-work/latest',
      SolutionRequests:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-solution-requests/latest',
      ResourceNeeded:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-resources-needed/latest',
      SolutionResources:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-solution-resources/latest',
      TeamsGroupManagement:
        'https://skylightmfemanager-dev.dev.target.com/property_ui_assets/v1/mfe-teams-group-management/latest',
      SpatioCreateProject: 'https://mfespatiocreateproject.dev.target.com/',
      SpatioListProjects: 'https://mfespatiolistprojects.dev.target.com/',
      UnspscLookupTool: 'https://unspsclookuptool.dev.target.com/',
      VaGridList: 'https://ipskylightmfes-vendorassignmentlist.dev.target.com',
      VaEdit: 'https://ipskylightmfes-va-edit.dev.target.com/',
      ZolPlannedBom: 'https://ipskylightmfes-planned-bom.dev.target.com/',
      ZolBomItems: 'https://ipskylightmfes-bom-items.dev.target.com/',
      ZolPublishedBoms:
        'https://ipskylightmfes-zolpublishedboms.dev.target.com/',
    },
    services: {
      all_locations: 'https://propertydesignlocationapi.dev.target.com',
      adjacencies: 'https://stgapi-internal.target.com/adjacencies/v1',
      bimIntegration: ' https://bimintegrationservice.dev.target.com',
      changeLogChangeOriginationUrl:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1/change_origination',
      changeLogCommunicationActionUrl:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1',
      changeLogCommunicationUrl:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1/project',
      pdTossProxyUploadUrl:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1/proxy/upload',
      changeLogFileAttachmentType:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1/file_attachment_type',
      changeLogDisciplineType:
        'https://changelogcommunicationapi.dev.target.com/property_design_change_logs/v1/discipline',
      changeReasonLookupUrl:
        'https://cpmcommons-api.dev.target.com/solution_requests/intake_lookup_field_values/change_reason',
      conceptRequestUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_concepts/v1/internal/',
      cpm_commons: 'https://cpmcommons-api.dev.target.com',
      directReportsUrl:
        'https://propertiesteamapi.dev.target.com/teams/v1/direct_reports',
      getDepartmentUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/departments',
      getDivisionsUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/division',
      hayStackUrl:
        'https://stgapi-internal.target.com/indirect_item_searches/v1',
      hayStackUrlv5: 'https://stgapi-internal.target.com/non_retail_items/v5',
      unspscIndererApiUrl:
        'https://stgapi-internal.target.com/commodity_code_search/v1',
      hzn: 'https://horizonapi.dev.target.com/indirect_procurement_items-v1',
      hznHaystackUrl:
        'https://horizonapi.dev.target.com/indirect_procurement_items-v1/haystack',
      i3: 'https://i3-aggregator-dev.dev.target.com/i3-aggregator-v1',
      impacted_areas:
        'https://impactedareasapi.dev.target.com/property_design_impacted_areas/v1',
      indirectItemCache: 'https://indirect-item-cache-api.dev.target.com',
      intake_functions:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/intake_functions',
      intake_types:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/intake_types',
      item_groups_divisions:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/division',
      item_groups:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/departments',
      ldap: 'https://stgapi-internal.target.com/ldap_directory_items/v2',
      leading_lookup:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/leading_lookups',
      location_types:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/location_types',
      locations_search: 'https://ppm-projects-api.dev.target.com',
      locationValidationUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/location_validation/',
      merchandise_groups:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/merchandise_hierarchy_groups',
      mosaicDocuments:
        'https://indirect-item-documents.dev.target.com/indirect_item_documents/v1',
      mosaicRelationShips:
        'https://mosaic-api-dev.dev.target.com/indirect_item_groups/v1',
      nri: 'https://stgapi-internal.target.com/non_retail_items/v4',
      nriv5: 'https://stgapi-internal.target.com/non_retail_items/v5',
      oii: 'https://oii-aggregator.dev.target.com/oii-aggregator-v1',
      oiiHaystackUrl:
        'https://oii-aggregator.dev.target.com/oii-aggregator-v1/haystack',
      locLevelTeamAssignmentsUrl:
        'https://propertiesteamapi.dev.target.com/teams/v1/location_team_assignment',
      pdCommon:
        'https://propertydesigncommonapi.dev.target.com/property_design_common_endpoints/v1',
      potential_impact:
        'https://solutionrequestsapi.dev.target.com/property_design_concepts/v1/internal/property_design_program_potential_impacts',
      potential_implementation_path:
        'https://solutionrequestsapi.dev.target.com/property_design_concepts/v1/internal/concepts/potential_implementation_path',
      procore: 'https://stage-api.target.com/cpm_outbound_project_settings/v1',
      project_groups_search:
        'https://propertyprojectgroups.dev.target.com/elastic/',
      project_groups: 'https://propertyprojectgroups.dev.target.com',
      projectHomePageCardView:
        'https://ppm-projects-api.dev.target.com/projects_internal/work_assignments',
      projectLocationsUrl:
        'https://stage-api.target.com/property_projects/v1/project_locations/_search',
      projectSchedulesElasticSearchUrl:
        'https://propertyprojectschedules.dev.target.com/elastic',
      projects: 'https://ppm-projects-api.dev.target.com',
      projectView:
        'https://propertyprojectgroups.dev.target.com/project_groups/work_assignments',
      property_project_groups: 'https://propertyprojectgroups.dev.target.com/',
      propertyProjectsV1Url:
        'https://ppm-projects-api.dev.target.com/property_projects/v1/projects',
      purchase:
        'https://indirectposervice.dev.target.com/indirect_requisition_fileupload/v1',
      ResourceNeededUrl:
        'https://propertiesteamapi.dev.target.com/teams/v1/property_design_resources_needed/',
      schedules: 'https://propertyprojectschedules.dev.target.com',
      seam_priorities:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/seam_priorities',
      site_areas: 'https://stgapi-internal.target.com/site_areas/v1/',
      solutionRequestUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/internal/',
      solutionRequestsToss:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/',
      tacticsUrl:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/tactics',
      teamAssignedRolesUrl:
        'https://propertiesteamapi.dev.target.com/teams/v1/roles_assigned',
      teamRolesUrl:
        'https://stage-api.target.com/cpm_outbound_roles/v1/roles?key=00ca0235d0b52d9604e6f92dee9d92b167703233',
      teamsResourceUrl: 'https://propertiesteamapi.dev.target.com/teams/v1',
      teamWorkerUrl: 'https://propertiesteamapi.dev.target.com/teams/v1/worker',
      workAssignmentForRequest:
        'https://solutionrequestsapi.dev.target.com/property_design_intake_requests/v1/work_assignments',
      spatioNameEmailSearch:
        'https://wsrbase.dev.target.com/workspace/v1/name/search',
      spatioLookupUrl: 'https://spatiobase.dev.target.com/spatio/v1',
      spatioProjectsListUrl:
        'https://spatiobase.dev.target.com/spatio/v1/projects',
      spatioProjectUrl: 'https://spatiobase.dev.target.com/spatio/v1/project',
      spatioSpaceHierarchyUrl:
        'https://wsrbase.dev.target.com/workspace/v1/space_hirearchy',
      spatioProjUploadUrl:
        'https://spatiobase.dev.target.com/spatio/v1/uploaddocument',
      spatioProjDownloadUrl:
        'https://spatiobase.dev.target.com/spatio/v1/downloaddocument',
      zoltar: 'https://zoltar-aggregator.dev.target.com/zoltar-aggregator-v1',
    },
    toss: {
      indirectposervice:
        'https://ttc.toss.target.com/dev-indirectposervice-templates',
    },
    url: 'https://skylight.dev.target.com/',
    webhooks: {
      slack:
        'https://horizonapi.dev.target.com/indirect_procurement_items-v1/slack-procurement/feedback',
    },
  },
  stg: {
    apiKey: '00ca0235d0b52d9604e6f92dee9d92b167703233', // skylight stage api key
    auth: { clientId: 'skylight_npe_im', redirectUri: window.location.origin },
    authPol: {
      clientId: 'skylight_npe_im',
      redirectUri: window.location.origin,
    },
    bannerEnvDisplay: 'STG',
    entitlements: {
      catalog: {
        read: 'APP-OAUTH2-SKY-CatalogMgtRead-NP',
        update: 'APP-OAUTH2-SKY-CatMgtUpdate-NP',
      },
      catItem: {
        create: 'APP-OAUTH2-SKY-CatItemCreate-NP',
        read: 'APP-OAUTH2-SKY-CatItemRead-NP',
        update: 'APP-OAUTH2-SKY-CatItemUpdate-NP',
      },
      concept: { update: 'APP-OAUTH2-SKY-ConceptUpdate-NP' },
      demand: {
        create: 'APP-OAUTH2-SKY-Demand-Write-NP',
        read: 'APP-OAUTH2-SKY-Demand-Read-NP',
      },
      i3Demand: { read: 'APP-OAUTH2-SKY-DemandRead-NP' },
      i3Order: { read: 'APP-OAUTH2-SKY-OrderRead-NP' },
      impactedAreas: { update: 'APP-OAUTH2-SKY-ImpAreaUpdate-NP' },
      intake: {
        create: 'APP-OAUTH2-SKY-IntkMgmtCreate-NP',
        read: 'APP-OAUTH2-SKY-IntkMgmtRead-NP',
        update: 'APP-OAUTH2-SKY-IntkMgmtUpdate-NP',
      },
      item: {
        create: 'APP-OAUTH2-SKY-ItemMgtCreate-NP',
        read: 'APP-OAUTH2-SKY-ItemMgtRead-NP',
        update: 'APP-OAUTH2-SKY-ItemMgtUpdate-NP',
        request: 'APP-OAUTH2-MOSAIC-REQUEST-NPE',
      },
      milestoneManagement: {
        admin: 'APP-OAUTH2-PPM-Admin-P',
        create: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
        read: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
        update: 'APP-OAUTH2-SKY-MilestoneMgmt-NP',
      },
      nri: {
        update: 'APP-OAUTH2-FIXT-FixtAdmin',
      },
      pol: { read: 'APP-CPMS' },
      procurement: {
        create: 'APP-OAUTH2-SKY-ProcureCreate-NP',
      },
      project: {
        create: 'APP-OAUTH2-SKY-ProjMgtCreate-NP',
        read: 'APP-OAUTH2-SKY-ProjMgtRead-NP',
        update: 'APP-OAUTH2-SKY-ProjMgtUpdate-NP',
      },
      projectLocations: {
        create: 'APP-OAUTH2-SKY-ProjLocCreate-NP',
        read: 'APP-OAUTH2-SKY-ProjLocRead-NP',
      },
      purchase: { create: 'APP-OAUTH2-SKY-ProcureCreate-NP' },
      schedule: { create: 'APP-OAUTH2-SKY-SchdMgmtCreate-NP' },
      sourcing: {
        create: 'APP-OAUTH2-SKY-SourcingCreate-NP',
        read: 'APP-OAUTH2-SKY-SourcingRead-NP',
        update: 'APP-OAUTH2-SKY-SourcingUpdate-NP',
      },
      team: { update: 'APP-OAUTH2-SKY-TeamMgmtUpdate-NP' },
      ttsAdmin: { create: 'APP-OAUTH2-SKY-TTSAdmin-NP' },
      bimAdmin: { read: 'APP-SPACE-INFRA-ADMIN' },
      bimManagementAdmins: { create: 'APP-OAUTH2-SKY-BimAdminCreate-NP' },
      spatio: {
        create: 'APP-OAUTH2-SKY-SptProjCreate-NP',
        read: 'APP-OAUTH2-SKY-SptProjRead-NP',
        update: 'APP-OAUTH2-SKY-SptProjUpdate-NP',
      },
      vendorAssignment: {
        create: 'APP-OAUTH2-SKY-VndAsnmt-Write-NP',
        read: 'APP-OAUTH2-SKY-VndAsnmt-Read-NP',
      },
      preBuild: {
        read: 'APP-OAUTH2-SKY-Prebuild-CPM-NP',
        create: 'APP-OAUTH2-SKY-Prebuild-CPM-NP',
      },
    },
    externalLinks: {
      horizon: 'https://horizon.dev.target.com',
      mosaic: 'https://mosaic.dev.target.com',
      mosaicApiBaseUrl: 'https://mosaic-api-dev.dev.target.com',
      mosaicApiUrl: 'https://mosaic-api-dev.dev.target.com',
      procore:
        'https://saml.iam.target.com/affwebservices/public/saml2sso?SPID=https://login.procore.com/',
      spatio_reports: 'https://greenfield.target.com/dashboard/19625/1?$',
    },
    microFrontEnds: {
      BimUserManagement:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-bim-user-management/latest',
      CatalogDetails:
        'https://ipskylightmfes-catalogdetails-stage.dev.target.com',
      CatalogImageBulkUpload:
        'https://ipskylightmfes-catalogimagebulkupload-stage.dev.target.com/',
      CatalogItemDetails:
        'https://ipskylightmfes-catalogitemdetails-stage.dev.target.com',
      CatalogItemDetailsAriba:
        'https://ipskylightmfes-catalogitemdetails-ariba-stage.dev.target.com',
      CatalogItemDetailsCatalogs:
        'https://ipskylightmfes-catalogitemdetails-catalogs-stage.dev.target.com',
      CatalogItemDetailsNri:
        'https://ipskylightmfes-catalogitemdetails-nri-stage.dev.target.com',
      CatalogItemMultiEdit:
        'https://ipskylightmfes-catalogitemmultiedit-stage.dev.target.com',
      CatalogItemNriCreate:
        'https://ipskylightmfes-catalogitemnricreate-stage.dev.target.com',
      CatalogItemSearch:
        'https://ipskylightmfes-catalogitemsearch-stage.dev.target.com',
      CatalogUpdatesList:
        'https://ipskylightmfes-catalogupdateslist-stage.dev.target.com',
      CpmFileUpload:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-cpm-file-upload/latest',
      DesignInitiatives:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-design-initiatives/latest',
      DirectReportMfeUrl:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-direct-report/latest',
      ExpiringContractsMfe:
        'https://ipskylightmfes-expiringcontracts-stage.dev.target.com',
      HomePage:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-home-page/latest',
      HomePageCpm:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-home-page-cpm/latest',
      HznItemDetails: 'https://ipskylightmfes-itemdetails-stage.dev.target.com',
      HznItemCatalogs:
        'https://ipskylightmfes-itemcatalogs-stage.dev.target.com',
      HznSourcingGroupDetails:
        'https://ipskylightmfes-sourcinggroupdetails-stage.dev.target.com',
      HznSourcingGroupItems:
        'https://ipskylightmfes-sourcinggroupitems-stage.dev.target.com',
      HznSourcingGroups:
        'https://ipskylightmfes-sourcinggroups-stage.dev.target.com',
      HznSourcingGroupsMultiEdit:
        'https://ipskylightmfes-sourcinggroupsmultiedit-stage.dev.target.com',
      HznSourcingGroupNotes:
        'https://ipskylightmfes-sourcingdetailsnotes-stage.dev.target.com',
      HznSupplierCatalogs:
        'https://ipskylightmfes-supplier-catalogs-stage.dev.target.com',
      I3Demand: 'https://mfei3demand-stage.dev.target.com/',
      I3DemandDetails: 'https://mfei3demanddetails-stage.dev.target.com/',
      I3DemandOrderDetails:
        'https://mfei3demandorderdetails-stage.dev.target.com/',
      I3DemandSignals: 'https://mfei3demandsignals-stage.dev.target.com/',
      I3Outbound: 'https://mfei3outbound-stage.dev.target.com/',
      I3OutboundOrder: 'https://mfei3outboundorder-stage.dev.target.com/',
      I3OutboundPart: 'https://mfei3outboundpart-stage.dev.target.com/',
      I3OutboundStore: 'https://mfei3outboundstore-stage.dev.target.com/',
      ImpactedAreas:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-impacted-areas/latest',
      ImplementationHistory:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-implementation-history/latest',
      IndirectItemDesignProcess:
        'https://mfeindirectitemdesignprocessnri-stage.dev.target.com/mfe.js',
      IndirectItemDetailsNri:
        'https://mfeindirectitemdetailsnri-stage.dev.target.com/',
      IndirectItemDocuments:
        'https://mfeindirectitemdocumentsnri-stage.dev.target.com/',
      IndirectItemMultiEdit:
        'https://ipskylightmfes-indirectitemsmultiedit-stage.dev.target.com',
      IndirectItemRelatedItems:
        'https://mfeindirectitemrelateditemsnri-stage.dev.target.com/mfe.js',
      IndirectItemSearch:
        'https://mfeindirectitemsearchnri-stage.dev.target.com/',
      IndirectItemMultiDocDownload:
        'https://mfeindirectitemmultidocdownload-stage.dev.target.com/mfe.js',
      IndirectItemReports:
        'https://nriskylightmfes-indirectitemreports-stage.dev.target.com/',
      IndirectItemVariations:
        'https://nriskylightmfes-indirectitemvariations-stage.dev.target.com/mfe.js',
      IndirectItemRequests:
        'https://nriskylightmfes-indirectitemrequests-stage.dev.target.com/mfe.js',
      ItemAttrCompletionReports:
        'https://ipskylightmfes-itemattrcompletion-stage.dev.target.com',
      LocationsSearch:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-locations-search/latest',
      LocLevelTeamAssignments:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-loc-team-assignments/latest',
      MilestoneManagement:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-milestone-management/latest',
      MissingAttributionIndirectPlanner:
        'https://ipskylightmfes-missingattrindirectplanner-stage.dev.target.com',
      PotentialImpact:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-potential-impact/latest',
      ProgramDetails:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-details/latest',
      ProgramList:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-list/latest',
      ProgramLocations:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-locations/latest',
      ProgramProjects:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-projects/latest',
      ProgramViewLocations:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-view-locations/latest',
      ProgramProjectChanges:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-program-project-changes/latest',
      ProjectDetails:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-project-details/latest',
      ProjectSchedules:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-project-schedules/latest',
      ProjectTeamUpdate:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-project-team-update/latest',
      Purchase: 'https://ipskylightmfes-volumebasedupload-stage.dev.target.com',
      PurchaseUploadsSearch:
        'https://ipskylightmfes-purchaseuploadssearch-stage.dev.target.com',
      ReassignWorkMfeUrl:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-reassign-work/latest',
      ResourceNeeded:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-resources-needed/latest',
      SolutionRequests:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-solution-requests/latest',
      SolutionResources:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-solution-resources/latest',
      TeamsGroupManagement:
        'https://stage-api.target.com/property_ui_assets/v1/mfe-teams-group-management/latest',
      SpatioCreateProject: 'https://mfespatiocreateproject-stg.dev.target.com/',
      SpatioListProjects: 'https://mfespatiolistprojects-stg.dev.target.com/',
      UnspscLookupTool: 'https://unspsclookuptool-stg.dev.target.com/',
      VaGridList:
        'https://ipskylightmfes-vendorassignmentlist-stage.dev.target.com',
      VaEdit: 'https://ipskylightmfes-va-edit-stage.dev.target.com/',
      ZolPlannedBom: 'https://ipskylightmfes-planned-bom-stage.dev.target.com/',
      ZolBomItems: 'https://ipskylightmfes-bom-items-stage.dev.target.com/',
      ZolPublishedBoms:
        'https://ipskylightmfes-zolpublishedboms-stage.dev.target.com/',
    },
    services: {
      all_locations: 'https://propertydesignlocationapi-stage.dev.target.com',
      adjacencies: 'https://stgapi-internal.target.com/adjacencies/v1',
      bimIntegration: 'https://bimintegrationservice-stage.dev.target.com',
      changeLogChangeOriginationUrl:
        'https://stage-api.target.com/property_design_change_logs/v1/change_origination',
      changeLogCommunicationActionUrl:
        'https://stage-api.target.com/property_design_change_logs/v1',
      pdTossProxyUploadUrl:
        'https://stage-api.target.com/property_design_change_logs/v1/proxy/upload',
      changeLogCommunicationUrl:
        'https://stage-api.target.com/property_design_change_logs/v1/project',
      changeLogFileAttachmentType:
        'https://stage-api.target.com/property_design_change_logs/v1/file_attachment_type',
      changeLogDisciplineType:
        'https://stage-api.target.com/property_design_change_logs/v1/discipline',
      changeReasonLookupUrl:
        'https://stage-api.target.com/capital_project_common_endpoints/v1/solution_requests/intake_lookup_field_values/change_reason',
      conceptRequestUrl:
        'https://stage-api.target.com/property_design_concepts/v1/internal/',
      cpm_commons:
        'https://stage-api.target.com/capital_project_common_endpoints/v1',
      directReportsUrl:
        'https://propertiesteamapi-stage.dev.target.com/teams/v1/direct_reports',
      getDepartmentUrl:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/departments',
      getDivisionsUrl:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/division',
      hayStackUrl:
        'https://stgapi-internal.target.com/indirect_item_searches/v1',
      hayStackUrlv5: 'https://stgapi-internal.target.com/non_retail_items/v5',
      unspscIndererApiUrl:
        'https://stgapi-internal.target.com/commodity_code_search/v1',
      hzn: 'https://horizonapi.dev.target.com/indirect_procurement_items-v1',
      hznHaystackUrl:
        'https://horizonapi.dev.target.com/indirect_procurement_items-v1/haystack',
      i3: 'https://i3-aggregator-stg.dev.target.com/i3-aggregator-v1',
      impacted_areas:
        'https://stage-api.target.com/property_design_impacted_areas/v1',
      indirectItemCache: 'https://indirect-item-cache-api.dev.target.com',
      intake_functions:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/intake_functions',
      intake_types:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/intake_types',
      item_groups_divisions:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/division',
      item_groups:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/departments',
      ldap: 'https://stgapi-internal.target.com/ldap_directory_items/v2',
      leading_lookup:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/leading_lookups',
      location_types:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/location_types',
      locations_search: 'https://stage-api.target.com/property_projects/v1',
      locationValidationUrl:
        'https://stage-api.target.com/property_design_intake_requests/v1/location_validation/',
      merchandise_groups:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/merchandise_hierarchy_groups',
      mosaicDocuments:
        'https://indirect-item-documents-stage.dev.target.com/indirect_item_documents/v1',
      mosaicRelationShips:
        'https://mosaic-api-stage.dev.target.com/indirect_item_groups/v1',
      nri: 'https://stgapi-internal.target.com/non_retail_items/v4',
      nriv5: 'https://stgapi-internal.target.com/non_retail_items/v5',
      oii: 'https://oii-aggregator.dev.target.com/oii-aggregator-v1',
      oiiHaystackUrl:
        'https://oii-aggregator.dev.target.com/oii-aggregator-v1/haystack',
      locLevelTeamAssignmentsUrl:
        'https://stage-api.target.com/teams/v1/location_team_assignment',
      pdCommon:
        'https://stage-api.target.com/property_design_common_endpoints/v1',
      potential_impact:
        'https://stage-api.target.com/property_design_concepts/v1/internal/property_design_program_potential_impacts',
      potential_implementation_path:
        'https://stage-api.target.com/property_design_concepts/v1/internal/concepts/potential_implementation_path',
      procore: 'https://stage-api.target.com/cpm_outbound_project_settings/v1',
      project_groups_search:
        'https://stage-api.target.com/property_project_groups/v1/elastic/',
      project_groups: 'https://stage-api.target.com/property_project_groups/v1',
      projectHomePageCardView:
        'https://stage-api.target.com/property_projects/v1/projects_internal/work_assignments',
      projectLocationsUrl:
        'https://stage-api.target.com/property_projects/v1/project_locations/_search',
      projectSchedulesElasticSearchUrl:
        'https://stage-api.target.com/property_project_schedules/v4/elastic',
      projects: 'https://stage-api.target.com/property_projects/v1',
      projectView:
        'https://stage-api.target.com/property_project_groups/v1/project_groups/work_assignments',
      property_project_groups:
        'https://stage-api.target.com/property_project_groups/v1/',
      propertyProjectsV1Url:
        'https://stage-api.target.com/property_projects/v1/projects',
      purchase:
        'https://indirectposervice-stg.dev.target.com/indirect_requisition_fileupload/v1',
      ResourceNeededUrl:
        'https://propertiesteamapi-stage.dev.target.com/teams/v1/property_design_resources_needed/',
      schedules: 'https://stage-api.target.com/property_project_schedules/v4',
      seam_priorities:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/seam_priorities',
      site_areas: 'https://stage-api.target.com/site_areas/v1/',
      solutionRequestUrl:
        'https://stage-api.target.com/property_design_intake_requests/v1/internal/',
      solutionRequestsToss:
        'https://stage-api.target.com/property_design_intake_requests/v1/',
      tacticsUrl:
        'https://stage-api.target.com/property_design_intake_requests/v1/tactics',
      teamAssignedRolesUrl:
        'https://propertiesteamapi-stage.dev.target.com/teams/v1/roles_assigned',
      teamRolesUrl:
        'https://stage-api.target.com/cpm_outbound_roles/v1/roles?key=00ca0235d0b52d9604e6f92dee9d92b167703233',
      teamsResourceUrl: 'https://stage-api.target.com/teams/v1',
      teamWorkerUrl: 'https://stage-api.target.com/teams/v1/worker',
      workAssignmentForRequest:
        'https://stage-api.target.com/property_design_intake_requests/v1/work_assignments',
      spatioNameEmailSearch:
        'https://wsrbase-stg.dev.target.com/workspace/v1/name/search',
      spatioLookupUrl: 'https://spatiobase-stg.dev.target.com/spatio/v1',
      spatioProjectsListUrl:
        'https://spatiobase-stg.dev.target.com/spatio/v1/projects',
      spatioProjectUrl:
        'https://spatiobase-stg.dev.target.com/spatio/v1/project',
      spatioSpaceHierarchyUrl:
        'https://wsrbase-stg.dev.target.com/workspace/v1/space_hirearchy',
      spatioProjUploadUrl:
        'https://spatiobase-stg.dev.target.com/spatio/v1/uploaddocument',
      spatioProjDownloadUrl:
        'https://spatiobase-stg.dev.target.com/spatio/v1/downloaddocument',
      zoltar: 'https://zoltar-aggregator.dev.target.com/zoltar-aggregator-v1',
    },
    toss: {
      indirectposervice:
        'https://ttc.toss.target.com/stg-indirectposervice-templates',
    },
    url: 'https://skylight-stage.dev.target.com/',
    webhooks: {
      slack:
        'https://horizonapi.dev.target.com/indirect_procurement_items-v1/slack-procurement/feedback',
    },
  },
  prod: {
    analyticsUrl: {
      external:
        'https://api.target.com/consumers/v1/ingest/internal/internal_app',
      internal:
        'https://api-internal.target.com/consumers/v1/ingest/internal/internal_app',
    },
    apiKey: '2b8f4540eca6b0c18b1f6dd54956fe3f7f633349',
    auth: {
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      clientId: 'skylight_prod_im',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      redirectUri: window.location.origin,
    },
    authPol: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      clientId: 'skylight_prod_im',
      logoutUrl:
        'https://logonservices.iam.partnersonline.com/login/responses/logoff.html',
      redirectUri: window.location.origin,
    },
    entitlements: {
      catalog: {
        read: 'APP-OAUTH2-SKY-CatalogMgtRead-P',
        update: 'APP-OAUTH2-SKY-CatMgtUpdate-P',
      },
      catItem: {
        create: 'APP-OAUTH2-SKY-CatItemCreate-P',
        read: 'APP-OAUTH2-SKY-CatItemRead-P',
        update: 'APP-OAUTH2-SKY-CatItemUpdate-P',
      },
      concept: { update: 'APP-OAUTH2-SKY-ConceptUpdate-P' },
      demand: {
        create: 'APP-OAUTH2-SKY-Demand-Write-P',
        read: 'APP-OAUTH2-SKY-Demand-Read-P',
      },
      i3Demand: { read: 'APP-OAUTH2-SKY-DemandRead-P' },
      i3Order: { read: 'APP-OAUTH2-SKY-OrderRead-P' },
      impactedAreas: { update: 'APP-OAUTH2-SKY-ImpAreaUpdate-P' },
      intake: {
        create: 'APP-OAUTH2-SKY-IntkMgmtCreate-P',
        read: 'APP-OAUTH2-SKY-IntkMgmtRead-P',
        update: 'APP-OAUTH2-SKY-IntkMgmtUpdate-P',
      },
      item: {
        create: 'APP-OAUTH2-SKY-ItemMgtCreate-P',
        read: 'APP-OAUTH2-SKY-ItemMgtRead-P',
        update: 'APP-OAUTH2-SKY-ItemMgtUpdate-P',
        request: 'APP-OAUTH2-MOSAIC-REQUEST-PROD',
      },
      milestoneManagement: {
        admin: 'APP-OAUTH2-PPM-Admin-P',
        create: 'APP-OAUTH2-SKY-MilestoneMgmt-P',
        read: 'APP-OAUTH2-SKY-MilestoneMgmt-P',
        update: 'APP-OAUTH2-SKY-MilestoneMgmt-P',
      },
      nri: {
        update: 'APP-OAUTH2-FIXT-FixtAdmin',
      },
      pol: { read: 'APP-CPMS' },
      procurement: {
        create: 'APP-OAUTH2-SKY-ProcureCreate-P',
      },
      project: {
        create: 'APP-OAUTH2-SKY-ProjMgtCreate-P',
        read: 'APP-OAUTH2-SKY-ProjMgtRead-P',
        update: 'APP-OAUTH2-SKY-ProjMgtUpdate-P',
      },
      projectLocations: {
        create: 'APP-OAUTH2-SKY-ProjLocCreate-P',
        read: 'APP-OAUTH2-SKY-ProjLocRead-P',
      },
      purchase: { create: 'APP-OAUTH2-SKY-ProcureCreate-P' },
      schedule: { create: 'APP-OAUTH2-SKY-SchdMgmtCreate-P' },
      sourcing: {
        create: 'APP-OAUTH2-SKY-SourcingCreate-P',
        read: 'APP-OAUTH2-SKY-SourcingRead-P',
        update: 'APP-OAUTH2-SKY-SourcingUpdate-P',
      },
      team: { update: 'APP-OAUTH2-SKY-TeamMgmtUpdate-P' },
      ttsAdmin: { create: 'APP-OAUTH2-SKY-TTSAdmin-P' },
      bimAdmin: { read: 'APP-SPACE-INFRA-ADMIN' },
      bimManagementAdmins: { create: 'APP-OAUTH2-SKY-BimAdminCreate-P' },
      spatio: {
        create: 'APP-OAUTH2-SKY-SptProjCreate-P',
        read: 'APP-OAUTH2-SKY-SptProjRead-P',
        update: 'APP-OAUTH2-SKY-SptProjUpdate-P',
      },
      vendorAssignment: {
        create: 'APP-OAUTH2-SKY-VndAsnmt-Write-P',
        read: 'APP-OAUTH2-SKY-VndAsnmt-Read-P',
      },
      preBuild: {
        read: 'APP-OAUTH2-SKY-Prebuild-CPM-P',
        create: 'APP-OAUTH2-SKY-Prebuild-CPM-P',
      },
    },
    env: 'prod',
    externalLinks: {
      horizon: 'https://horizon.prod.target.com',
      mosaic: 'https://mosaic.prod.target.com',
      mosaicApiBaseUrl: 'https://mosaic-apiv2.prod.target.com/',
      mosaicApiUrl: 'https://mosaic-apiv2.prod.target.com',
      procore:
        'https://saml.iam.target.com/affwebservices/public/saml2sso?SPID=https://login.procore.com/',
      spatio_reports: 'https://greenfield.target.com/dashboard/20234/0?$',
    },
    microFrontEnds: {
      BimUserManagement:
        'https://api.target.com/property_ui_assets/v1/mfe-bim-user-management/latest',
      CatalogDetails: 'https://ipskylightmfes-catalogdetails.prod.target.com',
      CatalogImageBulkUpload:
        'https://ipskylightmfes-catalogimagebulkupload.prod.target.com/',
      CatalogItemDetails:
        'https://ipskylightmfes-catalogitemdetails.prod.target.com',
      CatalogItemDetailsAriba:
        'https://ipskylightmfes-catalogitemdetails-ariba.prod.target.com',
      CatalogItemDetailsCatalogs:
        'https://ipskylightmfes-catalogitemdetails-catalogs.prod.target.com',
      CatalogItemDetailsNri:
        'https://ipskylightmfes-catalogitemdetails-nri.prod.target.com',
      CatalogItemMultiEdit:
        'https://ipskylightmfes-catalogitemmultiedit.prod.target.com',
      CatalogItemNriCreate:
        'https://ipskylightmfes-catalogitemnricreate.prod.target.com',
      CatalogItemSearch:
        'https://ipskylightmfes-catalogitemsearch.prod.target.com',
      CatalogUpdatesList:
        'https://ipskylightmfes-catalogupdateslist.prod.target.com',
      CpmFileUpload:
        'https://api.target.com/property_ui_assets/v1/mfe-cpm-file-upload/latest',
      DesignInitiatives:
        'https://api.target.com/property_ui_assets/v1/mfe-design-initiatives/latest',
      DirectReportMfeUrl:
        'https://api.target.com/property_ui_assets/v1/mfe-direct-report/latest',
      ExpiringContractsMfe:
        'https://ipskylightmfes-expiringcontracts.prod.target.com',
      HomePage:
        'https://api.target.com/property_ui_assets/v1/mfe-home-page/latest',
      HomePageCpm:
        'https://api.target.com/property_ui_assets/v1/mfe-home-page-cpm/latest',
      HznItemDetails: 'https://ipskylightmfes-itemdetails.prod.target.com',
      HznItemCatalogs: 'https://ipskylightmfes-itemcatalogs.prod.target.com',
      HznSourcingGroupDetails:
        'https://ipskylightmfes-sourcinggroupdetails.prod.target.com',
      HznSourcingGroupItems:
        'https://ipskylightmfes-sourcinggroupitems.prod.target.com',
      HznSourcingGroups:
        'https://ipskylightmfes-sourcinggroups.prod.target.com',
      HznSourcingGroupsMultiEdit:
        'https://ipskylightmfes-sourcinggroupsmultiedit.prod.target.com',
      HznSourcingGroupNotes:
        'https://ipskylightmfes-sourcingdetailsnotes.prod.target.com',
      HznSupplierCatalogs:
        'https://ipskylightmfes-supplier-catalogs.prod.target.com',
      I3Demand: 'https://mfei3demand.prod.target.com/',
      I3DemandDetails: 'https://mfei3demanddetails.prod.target.com/',
      I3DemandOrderDetails: 'https://mfei3demandorderdetails.prod.target.com/',
      I3DemandSignals: 'https://mfei3demandsignals.prod.target.com/',
      I3Outbound: 'https://mfei3outbound.prod.target.com/',
      I3OutboundOrder: 'https://mfei3outboundorder.prod.target.com/',
      I3OutboundPart: 'https://mfei3outboundpart.prod.target.com/',
      I3OutboundStore: 'https://mfei3outboundstore.prod.target.com/',
      ImpactedAreas:
        'https://api.target.com/property_ui_assets/v1/mfe-impacted-areas/latest',
      ImplementationHistory:
        'https://api.target.com/property_ui_assets/v1/mfe-implementation-history/latest',
      IndirectItemDesignProcess:
        'https://mfeindirectitemdesignprocessnri.prod.target.com/mfe.js',
      IndirectItemDetailsNri:
        'https://mfeindirectitemdetailsnri.prod.target.com/',
      IndirectItemDocuments:
        'https://mfeindirectitemdocumentsnri.prod.target.com/',
      IndirectItemMultiEdit:
        'https://ipskylightmfes-indirectitemsmultiedit.prod.target.com',
      IndirectItemRelatedItems:
        'https://mfeindirectitemrelateditemsnri.prod.target.com/mfe.js',
      IndirectItemSearch:
        'https://mfeindirectitemsearchnri.prod.target.com/mfe.js',
      IndirectItemMultiDocDownload:
        'https://mfeindirectitemmultidocdownload.prod.target.com/mfe.js',
      IndirectItemReports:
        'https://nriskylightmfes-indirectitemreports.prod.target.com/',
      IndirectItemVariations:
        'https://nriskylightmfes-indirectitemvariations.prod.target.com/mfe.js',
      IndirectItemRequests:
        'https://nriskylightmfes-indirectitemrequests.prod.target.com/mfe.js',
      ItemAttrCompletionReports:
        'https://ipskylightmfes-itemattrcompletion.prod.target.com',
      LocationsSearch:
        'https://api.target.com/property_ui_assets/v1/mfe-locations-search/latest',
      LocLevelTeamAssignments:
        'https://api.target.com/property_ui_assets/v1/mfe-loc-team-assignments/latest',
      MilestoneManagement:
        'https://api.target.com/property_ui_assets/v1/mfe-milestone-management/latest',
      MissingAttributionIndirectPlanner:
        'https://ipskylightmfes-missingattrindirectplanner.prod.target.com',
      PotentialImpact:
        'https://api.target.com/property_ui_assets/v1/mfe-potential-impact/latest',
      ProgramDetails:
        'https://api.target.com/property_ui_assets/v1/mfe-program-details/stable',
      ProgramList:
        'https://api.target.com/property_ui_assets/v1/mfe-program-list/latest',
      ProgramLocations:
        'https://api.target.com/property_ui_assets/v1/mfe-program-locations/latest',
      ProgramProjects:
        'https://api.target.com/property_ui_assets/v1/mfe-program-projects/stable',
      ProgramViewLocations:
        'https://api.target.com/property_ui_assets/v1/mfe-program-view-locations/latest',
      ProgramProjectChanges:
        'https://api.target.com/property_ui_assets/v1/mfe-program-project-changes/latest',
      ProjectDetails:
        'https://api.target.com/property_ui_assets/v1/mfe-project-details/latest',
      ProjectSchedules:
        'https://api.target.com/property_ui_assets/v1/mfe-project-schedules/stable',
      ProjectTeamUpdate:
        'https://api.target.com/property_ui_assets/v1/mfe-project-team-update/latest',
      Purchase: 'https://ipskylightmfes-volumebasedupload.prod.target.com',
      PurchaseUploadsSearch:
        'https://ipskylightmfes-purchaseuploadssearch.prod.target.com',
      ReassignWorkMfeUrl:
        'https://api.target.com/property_ui_assets/v1/mfe-reassign-work/latest',
      ResourceNeeded:
        'https://api.target.com/property_ui_assets/v1/mfe-resources-needed/latest',
      SolutionRequests:
        'https://api.target.com/property_ui_assets/v1/mfe-solution-requests/latest',
      SolutionResources:
        'https://api.target.com/property_ui_assets/v1/mfe-solution-resources/latest',
      TeamsGroupManagement:
        'https://api.target.com/property_ui_assets/v1/mfe-teams-group-management/latest',
      SpatioCreateProject: 'https://mfespatiocreateproject.prod.target.com/',
      SpatioListProjects: 'https://mfespatiolistprojects.prod.target.com/',
      UnspscLookupTool: 'https://unspsclookuptool.prod.target.com/',
      VaGridList: 'https://ipskylightmfes-vendorassignmentlist.prod.target.com',
      VaEdit: 'https://ipskylightmfes-va-edit.prod.target.com/',
      ZolPlannedBom: 'https://ipskylightmfes-planned-bom.prod.target.com/',
      ZolBomItems: 'https://ipskylightmfes-bom-items.prod.target.com/',
      ZolPublishedBoms:
        'https://ipskylightmfes-zolpublishedboms.prod.target.com/',
    },
    services: {
      all_locations: 'https://propertydesignlocationapi.prod.target.com',
      adjacencies: 'https://api-internal.target.com/adjacencies/v1',
      bimIntegration: 'https://bimintegrationservice-prod.prod.target.com',
      changeLogChangeOriginationUrl:
        'https://api.target.com/property_design_change_logs/v1/change_origination',
      changeLogCommunicationActionUrl:
        'https://api.target.com/property_design_change_logs/v1',
      changeLogCommunicationUrl:
        'https://api.target.com/property_design_change_logs/v1/project',
      pdTossProxyUploadUrl:
        'https://api.target.com/property_design_change_logs/v1/proxy/upload',
      changeLogFileAttachmentType:
        'https://api.target.com/property_design_change_logs/v1/file_attachment_type',
      changeLogDisciplineType:
        'https://api.target.com/property_design_change_logs/v1/discipline',
      changeReasonLookupUrl:
        'https://api.target.com/capital_project_common_endpoints/v1/solution_requests/intake_lookup_field_values/change_reason',
      conceptRequestUrl:
        'https://api.target.com/property_design_concepts/v1/internal/',
      cpm_commons: 'https://api.target.com/capital_project_common_endpoints/v1',
      directReportsUrl: 'https://api.target.com/teams/v1/direct_reports',
      getDepartmentUrl:
        'https://api.target.com/property_design_intake_requests/v1/internal/departments',
      getDivisionsUrl:
        'https://api.target.com/property_design_intake_requests/v1/internal/division',
      hayStackUrl: 'https://api-internal.target.com/indirect_item_searches/v1',
      hayStackUrlv5: 'https://api-internal.target.com/non_retail_items/v5',
      unspscIndererApiUrl:
        'https://api-internal.target.com/commodity_code_search/v1',
      hzn: 'https://horizonapi.prod.target.com/indirect_procurement_items-v1',
      hznHaystackUrl:
        'https://horizonapi.prod.target.com/indirect_procurement_items-v1/haystack',
      i3: 'https://i3-aggregator.prod.target.com/i3-aggregator-v1',
      impacted_areas:
        'https://api.target.com/property_design_impacted_areas/v1',
      indirectItemCache: 'https://indirect-item-cache-api.prod.target.com',
      intake_functions:
        'https://api.target.com/property_design_intake_requests/v1/internal/intake_functions',
      intake_types:
        'https://api.target.com/property_design_intake_requests/v1/internal/intake_types',
      item_groups_divisions:
        'https://api.target.com/property_design_intake_requests/v1/internal/division',
      item_groups:
        'https://api.target.com/property_design_intake_requests/v1/internal/departments',
      ldap: 'https://api-internal.target.com/ldap_directory_items/v2',
      leading_lookup:
        'https://api.target.com/property_design_intake_requests/v1/internal/leading_lookups',
      location_types:
        'https://api.target.com/property_design_intake_requests/v1/internal/location_types',
      locations_search: 'https://api.target.com/property_projects/v1',
      locationValidationUrl:
        'https://api.target.com/property_design_intake_requests/v1/location_validation/',
      merchandise_groups:
        'https://api.target.com/property_design_intake_requests/v1/internal/merchandise_hierarchy_groups',
      mosaicDocuments:
        'https://indirect-item-documents.prod.target.com/indirect_item_documents/v1',
      mosaicRelationShips:
        'https://mosaic-apiv2.prod.target.com/indirect_item_groups/v1',
      nri: 'https://api-internal.target.com/non_retail_items/v4',
      nriv5: 'https://api-internal.target.com/non_retail_items/v5',
      oii: 'https://oii-aggregator.prod.target.com/oii-aggregator-v1',
      oiiHaystackUrl:
        'https://oii-aggregator.prod.target.com/oii-aggregator-v1/haystack',
      locLevelTeamAssignmentsUrl:
        'https://api.target.com/teams/v1/location_team_assignment',
      pdCommon: 'https://api.target.com/property_design_common_endpoints/v1',
      potential_impact:
        'https://api.target.com/property_design_concepts/v1/internal/property_design_program_potential_impacts',
      potential_implementation_path:
        'https://api.target.com/property_design_concepts/v1/internal/concepts/potential_implementation_path',
      procore: 'https://api.target.com/cpm_outbound_project_settings/v1',
      project_groups_search:
        'https://api.target.com/property_project_groups/v1/elastic/',
      project_groups: 'https://api.target.com/property_project_groups/v1',
      projectHomePageCardView:
        'https://api.target.com/property_projects/v1/projects_internal/work_assignments',
      projectLocationsUrl:
        'https://api.target.com/property_projects/v1/project_locations/_search',
      projectSchedulesElasticSearchUrl:
        'https://api.target.com/property_project_schedules/v4/elastic',
      projects: 'https://api.target.com/property_projects/v1',
      projectView:
        'https://api.target.com/property_project_groups/v1/project_groups/work_assignments',
      property_project_groups:
        'https://api.target.com/property_project_groups/v1/',
      propertyProjectsV1Url:
        'https://api.target.com/property_projects/v1/projects',
      purchase:
        'https://indirectposervice.prod.target.com/indirect_requisition_fileupload/v1',
      ResourceNeededUrl:
        'https://api.target.com/teams/v1/property_design_resources_needed/',
      schedules: 'https://api.target.com/property_project_schedules/v4',
      seam_priorities:
        'https://api.target.com/property_design_intake_requests/v1/internal/seam_priorities',
      site_areas: 'https://api.target.com/site_areas/v1/',
      solutionRequestUrl:
        'https://api.target.com/property_design_intake_requests/v1/internal/',
      solutionRequestsToss:
        'https://api.target.com/property_design_intake_requests/v1/',
      tacticsUrl:
        'https://api.target.com/property_design_intake_requests/v1/tactics',
      teamAssignedRolesUrl: 'https://api.target.com/teams/v1/roles_assigned',
      teamRolesUrl:
        'https://api.target.com/cpm_outbound_roles/v1/roles?key=f5eff941a7cb926207a0dfa42902b5d751a36bcc',
      teamsResourceUrl: 'https://api.target.com/teams/v1',
      teamWorkerUrl: 'https://api.target.com/teams/v1/worker',
      workAssignmentForRequest:
        'https://api.target.com/property_design_intake_requests/v1/work_assignments',
      spatioNameEmailSearch:
        'https://wsrbase.prod.target.com/workspace/v1/name/search',
      spatioLookupUrl: 'https://spatiobase.prod.target.com/spatio/v1',
      spatioProjectsListUrl:
        'https://spatiobase.prod.target.com/spatio/v1/projects',
      spatioProjectUrl: 'https://spatiobase.prod.target.com/spatio/v1/project',
      spatioSpaceHierarchyUrl:
        'https://wsrbase.prod.target.com/workspace/v1/space_hirearchy',
      spatioProjUploadUrl:
        'https://spatiobase.prod.target.com/spatio/v1/uploaddocument',
      spatioProjDownloadUrl:
        'https://spatiobase.prod.target.com/spatio/v1/downloaddocument',
      zoltar: 'https://zoltar-aggregator.prod.target.com/zoltar-aggregator-v1',
    },
    title: 'Properties-Skylight-Application',
    toss: {
      indirectposervice:
        'https://ttc.toss.target.com/prod-indirectposervice-templates',
    },
    url: 'https://skylight.prod.target.com/',
    webhooks: {
      slack:
        'https://horizonapi.prod.target.com/indirect_procurement_items-v1/slack-procurement/feedback',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = appEnv != null ? envConfigs[appEnv] : {}

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
export const apiConfig = merge(commonConfig, config)

export default apiConfig

// The following line is purely for demonstration purposes and can be removed.
// if (process.env.NODE_ENV !== 'production') {
//   console.log(`${appEnv} ENV apiConfig:`, apiConfig)
// }
