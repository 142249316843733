import React, { useState, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Button,
  Grid,
  Heading,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {
  usePageInfo,
  useMediaQuery,
  useLocalStorage,
} from 'properties-ui-common'
import './MainWrapper.css'
import { MenuBar } from './MenuBar/MenuBar'
import { SideNavigation } from './SideNavigation/Sidenavigation'
import { AppRoutes } from './AppRoutes/AppRoutes'
import logo from '../../images/mosaic.png'

export const MainWrapper = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const [isRailOpen, setIsRailOpen] = useState(false)
  const [sideNavMinimized, setSideNavMinimized] = useState(false)
  const [bodyFullWidth, setBodyFullWidth] = useState(false)
  const locationRef = useRef<string | null>(null)
  const [localStorageThemeValue, setLocalStorageThemeValue] = useLocalStorage(
    'skylight.darkMode',
    false,
  )
  const [darkMode, setDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ||
      localStorageThemeValue,
  )

  const { heading, setHeading, breadcrumbs, setBreadcrumbs } = usePageInfo()
  const { pathname } = useLocation()
  let isMenuButton = useMediaQuery('(max-width: 1200px)')

  const AppText: any = () => {
    return (
      <>
        <Link
          to="/"
          className={
            sideNavMinimized ? 'sideNavHeaderPadding' : 'sideNavHeader'
          }
        >
          {(sideNavMinimized || !isSideNavOpen) && (
            <img src={logo} alt="" className="logo" height={32} width={32} />
          )}
          <Heading size={4} className={'hc-clr-contrast-weak'}>
            {sideNavMinimized && (pathname === '/' || heading?.length === 0)
              ? 'Skylight'
              : ''}
          </Heading>
        </Link>
        {heading.length > 0 && (
          <span className="pageName">
            <Heading className="colorBasedOnTheme" size={4}>
              {heading}
            </Heading>
          </span>
        )}
      </>
    )
  }

  React.useEffect(() => {
    setLocalStorageThemeValue(darkMode)
  }, [darkMode, setLocalStorageThemeValue])

  React.useEffect(() => {
    // this resets to blank by default
    // each page will need to call setBreadcrumbs() and/or setHeading individually to set crumbs and page heading
    setBreadcrumbs([])
    setHeading('')
  }, [setBreadcrumbs, setHeading, pathname, isMenuButton])

  React.useEffect(() => {
    if (!sideNavMinimized && locationRef.current !== pathname) {
      setIsSideNavOpen(false)
      locationRef.current = pathname
    }
  }, [pathname, sideNavMinimized])

  React.useEffect(() => {
    if (!isMenuButton) {
      setIsSideNavOpen(false)
    } else {
      if (sideNavMinimized) {
        setIsSideNavOpen(true)
      }
    }
  }, [isMenuButton, sideNavMinimized])

  // const isIntakeReadEntitled = entitlements?.intake?.read
  // const isIntakeCreateEntitled = entitlements?.intake?.create
  // const isIntakeUpdateEntitled = entitlements?.intake?.update

  return (
    <Layout
      fullWidth={bodyFullWidth}
      theme="default"
      className={
        bodyFullWidth && sideNavMinimized
          ? 'headerLeftPadding'
          : bodyFullWidth
            ? 'fullWidthBodyClass'
            : undefined
      }
      darkMode={darkMode}
    >
      {isMenuButton && (
        <Layout.MenuButton className="hc-pa-dense menuButton">
          <Button
            aria-label="Toggle Side Nav Menu"
            iconOnly
            onClick={() => {
              setIsSideNavOpen((prevState) => !prevState)
            }}
          >
            <FontAwesomeIcon icon={faBars as any} />
          </Button>
        </Layout.MenuButton>
      )}
      <Layout.Header
        className={`layoutHeaderLeft ${
          sideNavMinimized ? 'headerLeftMargin' : ''
        }`}
      >
        <Grid.Container align="center" justify="flex-start" spacing="dense">
          <Grid.Item className="PageTitle">{AppText()}</Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <MenuBar
        railOpen={isRailOpen}
        setRailOpen={(val: boolean | ((prevState: boolean) => boolean)) =>
          setIsRailOpen(val)
        }
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      {breadcrumbs && breadcrumbs.length > 0 && (
        <Layout.SectionHeader className="bodyHeader">
          <Breadcrumbs>
            {breadcrumbs.map((crumb: any) => (
              <Breadcrumbs.Item key={crumb.name} {...crumb}>
                {crumb.name}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
        </Layout.SectionHeader>
      )}
      <SideNavigation
        sideNavOpen={isSideNavOpen}
        setIsSideNavOpen={() => {
          setIsSideNavOpen((prevState) => !prevState)
        }}
        sideNavMinimized={sideNavMinimized}
        setSideNavMinimized={(
          val: boolean | ((prevState: boolean) => boolean),
        ) => {
          setBodyFullWidth(val)
          setSideNavMinimized(val)
        }}
        isMenuButton={isMenuButton}
      />
      <Layout.Body
        includeRail={isRailOpen ? false : true}
        className={
          isRailOpen && !sideNavMinimized
            ? 'mainBodyRail'
            : !isRailOpen && !sideNavMinimized
              ? 'mainBody'
              : !isRailOpen && sideNavMinimized
                ? 'mainBodyMinimized'
                : isRailOpen && sideNavMinimized
                  ? undefined
                  : 'mainBody'
        }
      >
        <AppRoutes />
      </Layout.Body>
    </Layout>
  )
}

export default MainWrapper
