import React from 'react'
import { DetailCard, MicroFrontEnd } from 'properties-ui-common'
import { useParams } from 'react-router-dom'
import { mfeNavUrls } from '../utility/url'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { apiConfig } from '../../../apiConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faSitemap } from '@fortawesome/free-solid-svg-icons'

export const ItemCreateDashboard = () => {
  const { id } = useParams<string>()
  return (
    <Grid.Container>
      <Grid.Item xs={7}>
        <MicroFrontEnd
          name="IndirectItemDetailsNri"
          src={apiConfig.microFrontEnds.IndirectItemDetailsNri}
          props={{
            mfeNavUrls,
            tcinKey: id,
          }}
          mode={MicroFrontEnd.Mode.Create}
        />
      </Grid.Item>
      <Grid.Item xs={5}>
        <Grid.Container>
          <Grid.Item xs={12}>
            <DetailCard
              avatar={<FontAwesomeIcon icon={faBook} />}
              headerText="Library"
              subHeaderText="Create new item in order to upload documents"
              avatarText={''}
              showEditButton={false}
              defaultExpanded
            ></DetailCard>
          </Grid.Item>
          <Grid.Item xs={12}>
            <DetailCard
              avatar={<FontAwesomeIcon icon={faSitemap} />}
              headerText="Related Items"
              subHeaderText="Create new item in order to view/create item relationships"
              avatarText={''}
              showEditButton={false}
              defaultExpanded
            ></DetailCard>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ItemCreateDashboard
